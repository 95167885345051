export default {
  saved: "Data was successfully saved",
  updated: "Data was successfully updated",
  deleted: "Data was successfully deleted",
  sent: "Request was successfully sent",
  error: "System error - Please contact the administrator",
  assetReviewed: "Asset was successfully reviewed",
  assetCreated: "The Asset has been successfully created and is being checked by the platform operator.",
  assetEdited: "The Asset has been successfully edited.",
  assetTypeCreated: "The Asset Type has been successfully created.",
  assetModelCreated: "The Asset Model has been successfully created.",
  assetTypeEdited: "The Asset Type has been successfully edited.",
  assetModelEdited: "The Asset Model has been successfully edited.",
  safetyNoteCreated: "The Safety Note has been successfully created.",
  safetyNoteEdited: "The Safety Note has been successfully edited.",
  errorCodeLetters: "The Identifier must have the format XX-XXXXXXXXXXXXXX (letter(s) number).",
  manufacturerCode: "Please enter a manufacturer code.",
  manufacturerName: "Please enter a manufacturer name.",
  selectTenant: "Please select an operator",
  selectAssetType: "Please select an asset type",
  selectAssetModel: "Please select an asset model",
  selectUnit: "Please select a unit",
  enterName: "Please enter a name",
  selectType: "Please select an input data",
  selectProject: "Please select a project",
  selectBillingAddress: "Please select a billing address",
  selectContact: "Please select at least one contact",
  exportSuccessful: "The export will be prepared in the background and send to you via email.",
  exportNotSuccessful: "Unfortunately there was an error with the request. Please try again later.",
};
