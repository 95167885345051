import { Box } from "@mui/material";
import Navigator from "components/organisms/Navigator";
import ErrorBoundary from "globalErrorhandling/ErrorBoundary";
import React, { useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { GetMainTitles } from "routes/utils/Router";
import theme from "themes/useCustomTheme";
import Header from "./Header";
import {
  containerStyles,
  HeaderBoxSC,
  HeaderWrapper,
  mainContainerStyles,
  NavigationContainerSC,
  RootWrapperSC,
} from "./styles";

export interface MainLayoutProps {
  noElevation?: boolean;
  noPadding?: boolean;
  open?: boolean;
  children?: React.ReactNode;
}

const MainLayout: React.FC<React.PropsWithChildren<MainLayoutProps>> = (props) => {
  const [open, setOpen] = useState<boolean>(true);
  const { assetModelId } = useParams();
  const { assetTypeId } = useParams();
  const { tenantId } = useParams();
  const { safetyNoteId } = useParams();

  const { id } = useParams();

  const deep = (
    <img
      src="/images/DEEPLogo_v2.svg"
      alt="image"
      style={{ height: "70px", width: "80px", margin: "8px -8px 0px -8px" }}
    />
  );
  const handleDrawer = (value: boolean) => setOpen(value);
  const location = useLocation();
  const showHeader =
    !location.pathname.split("/").includes("assets") &&
    !location.pathname.split("/").includes("new") &&
    !location.pathname.split("/").includes(`newAssets`) &&
    !location.pathname.split("/").includes(`${assetTypeId}`) &&
    !location.pathname.split("updateAsset/").includes(`${id}`) &&
    !location.pathname.split("updateAssetModel/").includes(`${assetModelId}`) &&
    !location.pathname.split("/").includes("newAssetType") &&
    !location.pathname.split("/").includes("createAssetModel") &&
    !location.pathname.split("updateNewEntry/").includes(`${id}`) &&
    !location.pathname.split("/").includes(`${safetyNoteId}`) &&
    !location.pathname.split("/").includes(`${tenantId}`);

  return (
    <RootWrapperSC theme={theme()}>
      <NavigationContainerSC>
        <Navigator setTitle={() => {}} open={open} handleDrawer={handleDrawer} />
      </NavigationContainerSC>
      <Box sx={containerStyles()}>
        <HeaderWrapper>
          <HeaderBoxSC onClick={() => null}>
            <Header brand={deep} tools={[]} />
            {showHeader && <GetMainTitles />}
          </HeaderBoxSC>
        </HeaderWrapper>
        <Outlet context={{ showHeader }} />
        <Box sx={mainContainerStyles}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Box>
      </Box>
    </RootWrapperSC>
  );
};

export default MainLayout;
