/* eslint-disable filenames/match-exported */
import translations from "translations"
import { QqlFormGenConfig } from "@ml-pa/react-apollo-form-tool/types"
const config: QqlFormGenConfig<AdditonalFieldsType> = {
  clientPath: "src/apollo/client",
  graphpQqlEndPoint: `http://localhost:42001/graphql`,
  //graphpQqlEndPoint: `https://app-deep-weeu-gatewaysvc-dev-01.azurewebsites.net/graphql`,
  // localSchema: "./src/graphql/pseudoSchema.graphql",
  // clientSchema: "./src/graphqlBase/client/clientSchema.graphql",
  // raftGqlSchemas: "./src/**/*raftExt.graphql",
  localConfigurator: true,
  // afterChange: "src/lib/formToolHelpers/testAfter.ts",
  localizationPath: "src/translations",
  // languages: ["de"],
  languages: ["de", "en"],
  useHC11: false,
  dataTypesMapping: {
    uuid: "string",
  },
  errorJoiner: (messages) => messages.reduce((acc, val) => `${acc} ${val}`, ""),
  baseErrorMessages: {
    required: () => translations.formValidation.baseErrorMessges.required,
    dataType: () => translations.formValidation.baseErrorMessges.dataType,
    minLength: (field) =>
      `${translations.formValidation.baseErrorMessges.length} ${field.minLength} ${translations.formValidation.baseErrorMessges.min}`,
    maxLength: (field) =>
      `${translations.formValidation.baseErrorMessges.length} ${field.maxLength} ${translations.formValidation.baseErrorMessges.max}`,
  },
  // onUpdateDataMiddleWare: "src/lib/onUpdateDataMiddleWare/index.ts",
  // beforeRemoteMuatation: "src/lib/formToolHelpers/beforeRemoteMutation/index.ts",
  out: "src/raft",
  elements: [
    "src/components/formElements/forms",
    "src/components/formElements/fieldSets",
    "src/components/formElements/fields",
    "src/components/formElements/customElements",
  ],
  forms: [
    {
      entity: "asset",
      operations: {
        update: {
          params: {
            id: "string",
          },
          additionalFields: {
            __routeConfirm: "boolean",
            __Confirm_Data_Correctness: "boolean",
          },
        },
        create: {
          params: {},
          additionalFields: { __routeConfirm: "boolean" },
        },
      },
    },
    {
      entity: "assetManufacturer",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "assetModel",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {
            assetTypeId: "string",
          },
        },
      },
    },
    {
      entity: "assetType",
      operations: {
        update: {
          additionalFields: { _test: "boolean" },
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "assetAttribute",
      operations: {
        update: {
          additionalFields: { _tenantRole: "string" },
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "assetAttributeSet",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "project",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "safetyNote",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "user",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
      },
    },
    {
      entity: "tenant",
      operations: {
        create: {
          additionalFields: {},
          params: {},
        },
        update: {
          params: {
            id: "string",
          },
        },
      },
    },
    {
      entity: "billingAddress",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
    {
      entity: "directory",
      operations: {
        update: {
          params: {
            id: "string",
          },
        },
        create: {
          params: {},
        },
      },
    },
  ],
}

export default config
export type AdditonalFieldsType = {
  test: string
}
