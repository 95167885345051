import { Configuration, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const loginRequest: RedirectRequest = {
  scopes: [`${process.env.REACT_APP_ADAL_CLIENT_ID}`, "openid"],
};

const baseAuthority = `https://${process.env.REACT_APP_ADAL_TENANT ?? ""}.b2clogin.com/windfgwpublic.onmicrosoft.com/`;
export const msalConfig: Configuration = {
  auth: {
    authority: `${baseAuthority}${process.env.REACT_APP_ADAL_POLICY_ID ?? ""}`,
    clientId: process.env.REACT_APP_ADAL_CLIENT_ID ?? "",
    knownAuthorities: [`https://${process.env.REACT_APP_ADAL_TENANT ?? ""}.b2clogin.com`],
    redirectUri: window.location.origin,
  },  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: false,
  },
};

export const getPasswortResetUrl = () => {
  return `${baseAuthority}oauth2/v2.0/authorize?p=${process.env.REACT_APP_ADAL_PASSWORD_RESET_FLOW}&client_id=${process.env.REACT_APP_ADAL_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${window.location.origin}&scope=openid&response_type=id_token&prompt=login`;
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
// export const loginRequest: PopupRequest = {
//   scopes: ["profile", "openid", "api://50f22f29-4c0f-4192-be66-87a89dd8f531/DeepBE.Request"],
// };

export const msalInstance = new PublicClientApplication(msalConfig);
export const logIn = () => msalInstance.loginRedirect(loginRequest);
export const getMsalUser = () => msalInstance.getAllAccounts()[0];
export const getUserName = () => msalInstance.getAllAccounts()[0]?.name ?? "";
export const getInitials = () => {
  const name = getUserName();
  const names = name.split(" ");
  const initials = names.map((n) => n.substring(0, 1).toUpperCase()).join("");
  return initials;
};
export const useLogIn = () => {
  return () =>
    logIn().catch((e) => {
      console.log(e);
    });
};

export const getTokenAsync = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    return msalInstance.loginRedirect();
  }
  const request = {
    ...loginRequest,
    account: activeAccount ?? accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

export const getDecodedJWT = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return msalInstance.getActiveAccount();
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const logout = async () => msalInstance.logoutRedirect();
